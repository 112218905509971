html {
  height: 100%;
  background: #fafafa;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100vh;
}
.card {
  padding: 1rem;
  margin-top: 1rem;
}

.card--table {
  padding: 0;
}

.card--table > table {
  min-width: 720px;
}

.card--success {
  background-color: #81C784 !important;
}

.card--purple {
  background-color: #EDD8FF !important;
}

.pt {
  padding-top: 12px;
}

.pb {
  padding-bottom: 12px;
}

.py {
  padding-top: 12px;
  padding-bottom: 12px;
}

.chart-holder {
  position: relative;
}

.list-description {
  font-weight: 400;
  color: #888;
}

.react-mde {
  margin-top: 20px;
}

.of-x-scroll {
  overflow-x: scroll !important;
}

.boa-link, .boa-link:visited, .boa-link:active {
  color: #4f4d64;
  text-decoration: none;
}

.boa-link:hover {
  text-decoration: underline;
}

.lato-font {
  font-family: 'Lato', sans-serif !important;
}

.mapboxgl-popup-content {
  background: rgba(255, 255, 255, 0.65) !important;
}